export const HttpMethod = {
  Get: "GET",
  Post: "POST",
  Put: "PUT",
  Patch: "PATCH",
  Delete: "DELETE",
};

const ApiRoutes = {
  Dashboard: {
    Data: {
      Endpoint: "/dashboard",
      Method: HttpMethod.Post,
    },
    FranchiseData: {
      Endpoint: "/franchiseDashboard",
      Method: HttpMethod.Post,
    },
    staffDetail : {
      Endpoint: "/staffDetail",
      Method: HttpMethod.Post,
    },
    getNotification : {
      Endpoint: "/getNotification",
      Method: HttpMethod.Post,
    },
    readNotification : {
      Endpoint: "/notificationRead",
      Method: HttpMethod.Post,
    }
  },
  CheckOut: {
    Data: {
      Endpoint: "/addAllCart",
      Method: HttpMethod.Post,
    },
  },
  Ledger: {
    Data: {
      Endpoint: "/addAllCart",
      Method: HttpMethod.Post,
    },
  },
  Auth: {
    Login: {
      Endpoint: "/login",
      Method: HttpMethod.Post,
    },
  },
  Cod: {
    Cod: {
      Endpoint: "/frenchiseProductCOD",
      Method: HttpMethod.Post,
    },
  },
  User: {
    All: {
      Endpoint: "/getAllUser",
      Method: HttpMethod.Post,
    },
    AllFranchiseUser: {
      Endpoint: "/getAllFrenchiseUser",
      Method: HttpMethod.Post,
    },
    AllStateDistrictUser: {
      Endpoint: "/getAllStateDistrictUser",
      Method: HttpMethod.Post,
    },
    AllUserCod: {
      Endpoint: "/getAllUserCOD",
      Method: HttpMethod.Post,
    },
    AllFrenchiseCod: {
      Endpoint: "/getAllFranchiseCOD",
      Method: HttpMethod.Post,
    },
    AddAcceptPayment: {
      Endpoint: "/codPaymentAccept",
      Method: HttpMethod.Post,
    },
    AllCartDetail: {
      Endpoint: "/getCartDetail",
      Method: HttpMethod.Post,
    },
    UserById: {
      Endpoint: "/getUserById",
      Method: HttpMethod.Post,
    },
    LeadAll: {
      Endpoint: "/getProductLead",
      Method: HttpMethod.Post,
    },
    ProductLeadDelete: {
      Endpoint: "/deleteProductLead",
      Method: HttpMethod.Post,
    },
    ServiceLeadDelete: {
      Endpoint: "/deleteServiceLead",
      Method: HttpMethod.Post,
    },
    ServiceLeadAll: {
      Endpoint: "/getServiceLead",
      Method: HttpMethod.Post,
    },
    EditUser: {
      Endpoint: "/userActive",
      Method: HttpMethod.Post,
    },
 

  },
  View:{
    GetLEdger: {
      Endpoint: "/getLeadger",
      Method: HttpMethod.Post,
    },
  },
  Banner: {
    All: {
      Endpoint: "/getAllBanner",
      Method: HttpMethod.Post,
    },
    BannerById: {
      Endpoint: "/getBannerById",
      Method: HttpMethod.Post,
    },
    AddBanner: {
      Endpoint: "/addBanner",
      Method: HttpMethod.Post,
    },
    EditBanner: {
      Endpoint: "/updateBanner",
      Method: HttpMethod.Post,
    },
    DeleteBanner: {
      Endpoint: "/deleteBanner",
      Method: HttpMethod.Post,
    },
  },
  Category: {
    All: {
      Endpoint: "/getAllCategory",
      Method: HttpMethod.Post,
    },
    CategoryById: {
      Endpoint: "/getCategoryById",
      Method: HttpMethod.Post,
    },
    AddCategory: {
      Endpoint: "/addCategory",
      Method: HttpMethod.Post,
    },
    EditCategory: {
      Endpoint: "/updateCategory",
      Method: HttpMethod.Post,
    },
    DeleteCategory: {
      Endpoint: "/deleteCategory",
      Method: HttpMethod.Post,
    },
  },

  ServiceCategory: {
    All: {
      Endpoint: "/getAllServiceCategory",
      Method: HttpMethod.Post,
    },
    CategoryById: {
      Endpoint: "/getServiceCategoryById",
      Method: HttpMethod.Post,
    },
    AddCategory: {
      Endpoint: "/addServiceCategory",
      Method: HttpMethod.Post,
    },
    EditCategory: {
      Endpoint: "/updateServiceCategory",
      Method: HttpMethod.Post,
    },
    DeleteCategory: {
      Endpoint: "/deleteServiceCategory",
      Method: HttpMethod.Post,
    },
  },
  SubCategory: {
    All: {
      Endpoint: "/getAllSubCategory",
      Method: HttpMethod.Post,
    },
    SubCategoryById: {
      Endpoint: "/getSubCategoryById",
      Method: HttpMethod.Post,
    },
    subCategoryByCategoryIdConfig: {
      Endpoint: "/getSubCategoryByCategoryId",
      Method: HttpMethod.Post,
    },
    AddSubCategory: {
      Endpoint: "/addSubCategory",
      Method: HttpMethod.Post,
    },
    EditSubCategory: {
      Endpoint: "/updateSubCategory",
      Method: HttpMethod.Post,
    },
    DeleteSubCategory: {
      Endpoint: "/deleteCategory",
      Method: HttpMethod.Post,
    },
  },
  Attendence: {
    All: {
      Endpoint: "/getAllAttendence",
      Method: HttpMethod.Post,
    },
    AttendenceById: {
      Endpoint: "/getAttendenceById",
      Method: HttpMethod.Post,
    },
    AddAttendence: {
      Endpoint: "/addAttendence",
      Method: HttpMethod.Post,
    },
    EditAttendence: {
      Endpoint: "/updateAttendence",
      Method: HttpMethod.Post,
    },
    DeleteAttendence: {
      Endpoint: "/deleteAttendence",
      Method: HttpMethod.Post,
    },
  },
  Holiday: {
    All: {
      Endpoint: "/getAllHoliday",
      Method: HttpMethod.Post,
    },
    HolidayById: {
      Endpoint: "/getHolidayById",
      Method: HttpMethod.Post,
    },
    AddHoliday: {
      Endpoint: "/addHoliday",
      Method: HttpMethod.Post,
    },
    EditHoliday: {
      Endpoint: "/updateHoliday",
      Method: HttpMethod.Post,
    },
    DeleteHoliday: {
      Endpoint: "/deleteHoliday",
      Method: HttpMethod.Post,
    },
  },
  Service: {
    All: {
      Endpoint: "/getAllService",
      Method: HttpMethod.Post,
    },
    AllPURCHASESERVICE: {
      Endpoint: "/getPurchasingService",
      Method: HttpMethod.Post,
    },
    ServiceById: {
      Endpoint: "/getServiceById",
      Method: HttpMethod.Post,
    },
    AddService: {
      Endpoint: "/addService",
      Method: HttpMethod.Post,
    },
    EditService: {
      Endpoint: "/updateService",
      Method: HttpMethod.Post,
    },
    DeleteService: {
      Endpoint: "/deleteService",
      Method: HttpMethod.Post,
    },
    GETService : {
      Endpoint: "/getService",
      Method: HttpMethod.Post,
    },
    GETServiceType : {
      Endpoint: "/getTypeByServiceId",
      Method: HttpMethod.Post,
    },
    GETServicePackage : {
      Endpoint: "/getPackageByTypeId",
      Method: HttpMethod.Post,
    }
  },

  ServiceType: {
    All: {
      Endpoint: "/getAllType",
      Method: HttpMethod.Post,
    },
    ById: {
      Endpoint: "/getTypeById",
      Method: HttpMethod.Post,
    },
    ByServiceId: {
      Endpoint: "/getTypeByServiceId",
      Method: HttpMethod.Post,
    },
    Add: {
      Endpoint: "/addType",
      Method: HttpMethod.Post,
    },
    Edit: {
      Endpoint: "/updateType",
      Method: HttpMethod.Post,
    },
    Delete: {
      Endpoint: "/deleteType",
      Method: HttpMethod.Post,
    },
  },


  ServicePackage: {
    All: {
      Endpoint: "/getAllPackage",
      Method: HttpMethod.Post,
    },
    ById: {
      Endpoint: "/getPackageById",
      Method: HttpMethod.Post,
    },
    Add: {
      Endpoint: "/addPackage",
      Method: HttpMethod.Post,
    },
    Edit: {
      Endpoint: "/updatePackage",
      Method: HttpMethod.Post,
    },
    Delete: {
      Endpoint: "/deletePackge",
      Method: HttpMethod.Post,
    },
  },
  GraminSathiCommission: {
    All: {
      Endpoint: "/getGraminSathiCommision",
      Method: HttpMethod.Post,
    },
  },

  Product: {
    All: {
      Endpoint: "/getAllProduct",
      Method: HttpMethod.Post,
    },
    AllProductFrencise: {
      Endpoint: "/getFranchiseProduct",
      Method: HttpMethod.Post,
    },
    ProductById: {
      Endpoint: "/getProductById",
      Method: HttpMethod.Post,
    },
    AddProduct: {
      Endpoint: "/addProduct",
      Method: HttpMethod.Post,
    },
    addProductVarients: {
      Endpoint: "/addProductVarients",
      Method: HttpMethod.Post,
    },
    EditProduct: {
      Endpoint: "/updateProduct",
      Method: HttpMethod.Post,
    },
    EditProductVariant: {
      Endpoint: "/updateProductVarients",
      Method: HttpMethod.Post,
    },
    DeleteProduct: {
      Endpoint: "/deleteProduct",
      Method: HttpMethod.Post,
    },
  },
  Frencise: {
    All: {
      Endpoint: "/getAllFrencisePending",
      Method: HttpMethod.Post,
    },
    FrenciseAccept: {
      Endpoint: "/getAllFrencise",
      Method: HttpMethod.Post,
    },
    FrenciseById: {
      Endpoint: "/getFrenciseById",
      Method: HttpMethod.Post,
    },
    FrenciseCheck: {
      Endpoint: "/frenciseCheck",
      Method: HttpMethod.Post,
    },
    AddFrencise: {
      Endpoint: "/addFrencise",
      Method: HttpMethod.Post,
    },
    EditFrencise: {
      Endpoint: "/editFrencise",
      Method: HttpMethod.Post,
    },
    DeleteFrencise: {
      Endpoint: "/deleteFrencise",
      Method: HttpMethod.Post,
    },
    FrenchiseUser: {
      Endpoint: "/getAllFrenchiseUser",
      Method: HttpMethod.Post,
    },
    FranchiseTransaction: {
      Endpoint: "/getFranchiseTransaction ",
      Method: HttpMethod.Post,
    },
    FrenchiseOrder: {
      Endpoint: "/getFrenchiseOrder ",
      Method: HttpMethod.Post,
    },
  },
  Role: {
    All: {
      Endpoint: "/getAllRole",
      Method: HttpMethod.Post,
    },
    RoleById: {
      Endpoint: "/getRoleById",
      Method: HttpMethod.Post,
    },
    AddRole: {
      Endpoint: "/addRole",
      Method: HttpMethod.Post,
    },
    EditRole: {
      Endpoint: "/updateRole",
      Method: HttpMethod.Post,
    },
    DeleteRole: {
      Endpoint: "/deleteRole",
      Method: HttpMethod.Post,
    },
  },
  Staff: {
    All: {
      Endpoint: "/getAllStaff",
      Method: HttpMethod.Post,
    },
    StaffById: {
      Endpoint: "/getStaffById",
      Method: HttpMethod.Post,
    },
    AddStaff: {
      Endpoint: "/addStaff",
      Method: HttpMethod.Post,
    },
    EditStaff: {
      Endpoint: "/updateStaff",
      Method: HttpMethod.Post,
    },
    DeleteStaff: {
      Endpoint: "/deleteStaff",
      Method: HttpMethod.Post,
    },
  },
  Farmer: {
    All: {
      Endpoint: "/getAllServiceRequest",
      Method: HttpMethod.Post,
    },
    FarmerById: {
      Endpoint: "/getServiceRequestById",
      Method: HttpMethod.Post,
    },
    AddFarmer: {
      Endpoint: "/addServiceRequest",
      Method: HttpMethod.Post,
    },
    EditFarmer: {
      Endpoint: "/updateFarmer",
      Method: HttpMethod.Post,
    },
    DeleteFarmer: {
      Endpoint: "/deleteFarmer",
      Method: HttpMethod.Post,
    },
    AddFarmerRegistration: {
      Endpoint: "/addFarmerRegistration",
      Method: HttpMethod.Post,
    },
  },
  District: {
    AllDistrict: {
      Endpoint: "/getAllDistrict",
      Method: HttpMethod.Post,
    },
    DistrictById: {
      Endpoint: "/getDistrictById",
      Method: HttpMethod.Post,
    },
    DistrictByStateId: {
      Endpoint: "/getDistrictByStateId",
      Method: HttpMethod.Post,
    },
    AddDistrict: {
      Endpoint: "/addDistrict",
      Method: HttpMethod.Post,
    },
    EditDistrict: {
      Endpoint: "/updateDistrict",
      Method: HttpMethod.Post,
    },
    DeleteDistrict: {
      Endpoint: "/deleteDistrict",
      Method: HttpMethod.Post,
    },
  },
  State: {
    AllState: {
      Endpoint: "/getAllState",
      Method: HttpMethod.Post,
    },
    StateById: {
      Endpoint: "/getStateById",
      Method: HttpMethod.Post,
    },
    AddState: {
      Endpoint: "/addState",
      Method: HttpMethod.Post,
    },
    EditState: {
      Endpoint: "/updateState",
      Method: HttpMethod.Post,
    },
    DeleteState: {
      Endpoint: "/deleteState",
      Method: HttpMethod.Post,
    },
  },
  ZipCode: {
    AllZipCode: {
      Endpoint: "/getAllZip",
      Method: HttpMethod.Post,
    },
    ZipCodeById: {
      Endpoint: "/getZipById",
      Method: HttpMethod.Post,
    },
    AddZipCode: {
      Endpoint: "/addZip",
      Method: HttpMethod.Post,
    },
    EditZipCode: {
      Endpoint: "/editZip",
      Method: HttpMethod.Post,
    },
    DeleteZipCode: {
      Endpoint: "/deleteZip",
      Method: HttpMethod.Post,
    },
  },
  Advisory: {
    AllNotification: {
      Endpoint: "/notificationHistory",
      Method: HttpMethod.Post,
    },
    sendNotification: {
      Endpoint: "/sendMessage",
      Method: HttpMethod.Post,
    },
    personalAdvisory:{
      Endpoint: "/sendPersonlised",
      Method: HttpMethod.Post,
    },
    allPersonalAdvisory:{
      Endpoint: "/historyPersonlised",
      Method: HttpMethod.Post,
    },
    fertigationAdvisory:{
      Endpoint: "/sendFertigationInfo",
      Method: HttpMethod.Post,
    },
    allFertigationAdvisory:{
      Endpoint: "/historyFertigation",
      Method: HttpMethod.Post,
    },
    pesticideAdvisory:{
      Endpoint: "/sendPesticideInfo",
      Method: HttpMethod.Post,
    },
    allPesticideAdvisory:{
      Endpoint: "/historyPesticide",
      Method: HttpMethod.Post,
    }
  },
  Commissiom: {
    All: {
      Endpoint: "/getAllCommission",
      Method: HttpMethod.Post,
    },
    CommissiomById: {
      Endpoint: "/getCommissionById",
      Method: HttpMethod.Post,
    },
    AddCommissiom: {
      Endpoint: "/addCommission",
      Method: HttpMethod.Post,
    },
    EditCommissiom: {
      Endpoint: "/updateCommission",
      Method: HttpMethod.Post,
    },
    DeleteCommissiom: {
      Endpoint: "/deleteCommission",
      Method: HttpMethod.Post,
    },
  },
  Transaction: {
    AllFrTransaction: {
      Endpoint: "/getAllFranchiseTransaction",
      Method: HttpMethod.Post,
    },
    FranchiseTransaction: {
      Endpoint: "/getFranchiseTransaction ",
      Method: HttpMethod.Post,
    },
    UserTransaction: {
      Endpoint: "/getUserTransaction ",
      Method: HttpMethod.Post,
    },
  },
  Order: {
    AllUserOrder: {
      Endpoint: "/getAllUserOrder",
      Method: HttpMethod.Post,
    },
    FranchiseSettlement: {
      Endpoint: "/getFranchiseSettlement",
      Method: HttpMethod.Post,
    },
    ServiceTrack: {
      Endpoint: "/serviceTrack",
      Method: HttpMethod.Post,
    },
    SettlementDetail: {
      Endpoint: "/getSettlementDetail",
      Method: HttpMethod.Post,
    },
    AllFranchiseOrder: {
      Endpoint: "/getAllFrenchiseOrder ",
      Method: HttpMethod.Post,
    },
    OrderDetail: {
      Endpoint: "/getCartDetail",
      Method: HttpMethod.Post,
    },
    OrderDetailUser: {
      Endpoint: "/getServiceOrderDetail ",
      Method: HttpMethod.Post,
    },
    CodAcceptPaymnet: {
      Endpoint: "/codPaymentAccept ",
      Method: HttpMethod.Post,
    },
    SendCODOTP: {
      Endpoint: "/sendCODOTP",
      Method: HttpMethod.Post,
    },
    ReSendCODOTP: {
      Endpoint: "/resendCODOTP",
      Method: HttpMethod.Post,
    },
    VerifyCOD: {
      Endpoint: "/verifyCODOTP",
      Method: HttpMethod.Post,
    },
    FrenchiseAllUserOrder: {
      Endpoint: "/getFrenchiseAllUserOrder",
      Method: HttpMethod.Post,
    },
  },
  Loan: {
    getLoanDetail: {
      Endpoint: "/getApplicantDetails",
      Method: HttpMethod.Post,
    },
    addLoan: {
      Endpoint: "/addLoan",
      Method: HttpMethod.Post,
    },
    getLoanType: {
      Endpoint: "/getLoanType",
      Method: HttpMethod.Post,
    },
   
  },


};

export default ApiRoutes;
