import { lazy } from "react";
// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import PurchaseHistory from "views/utilities/PurchaseHistory/PurchaseHistory";
import ViewPurchaseHistory from "views/utilities/PurchaseHistory/ViewPurchaseHistory";
import { Protected, IsLogin } from 'utils/common.utils';
import { getUserLocal } from 'utils/localStorage.util';
const userDetails = getUserLocal(); //User Role Access Routes
// dashboard routing
const Dashboard = Loadable(lazy(() => import("views/dashboard/Default")));

const FranchiseDashboard = Loadable(lazy(() => import("views/dashboard/Default/franchiseDashboard")));


// utilities routing
const Users = Loadable(lazy(() => import("views/utilities/users/users")));
const ViewUsers = Loadable(
  lazy(() => import("views/utilities/users/view-user"))
);

const FranchiseUsers = Loadable(lazy(() => import("views/utilities/users/franchiseUser")));
const FranchiseCard = Loadable(lazy(() => import("views/utilities/Franchise/FranchiseCard")));


// banner routing
const Banner = Loadable(lazy(() => import("views/utilities/Banner/banner")));
const AddBanner = Loadable(
  lazy(() => import("views/utilities/Banner/add-banner"))
);
const EditBanner = Loadable(
  lazy(() => import("views/utilities/Banner/edit-banner"))
);

// category routing
const Category = Loadable(
  lazy(() => import("views/utilities/category/category"))
);
const AddCategory = Loadable(
  lazy(() => import("views/utilities/category/add-category"))
);
const EditCategory = Loadable(
  lazy(() => import("views/utilities/category/edit-category"))
);
const ViewCategory = Loadable(
  lazy(() => import("views/utilities/category/view-category"))
);

// service Product Routes
const ServiceAll = Loadable(lazy(() => import("views/utilities/serviceProduct/service/getAll")));
const AddService = Loadable(lazy(() => import("views/utilities/serviceProduct/service/add")));
const EditService = Loadable(lazy(() => import("views/utilities/serviceProduct/service/edit")));
const ViewService = Loadable(lazy(() => import("views/utilities/serviceProduct/service/view")));

const ServiceCategoryAll = Loadable(lazy(() => import("views/utilities/serviceProduct/category/categoryAll")));
const AddCategoryService = Loadable(lazy(() => import("views/utilities/serviceProduct/category/add")));
const EditCategoryService = Loadable(lazy(() => import("views/utilities/serviceProduct/category/edit")));
const ViewCategoryService = Loadable(lazy(() => import("views/utilities/serviceProduct/category/view")));

const ServiceTypeAll = Loadable(lazy(() => import("views/utilities/serviceProduct/serviceType/all")));
const AddServiceType = Loadable(lazy(() => import("views/utilities/serviceProduct/serviceType/add")));
const EditServiceType = Loadable(lazy(() => import("views/utilities/serviceProduct/serviceType/edit")));
const ViewServiceType = Loadable(lazy(() => import("views/utilities/serviceProduct/serviceType/view")));



const ServicePackageAll = Loadable(lazy(() => import("views/utilities/serviceProduct/servicePackage/all")));
const AddServicePackage = Loadable(lazy(() => import("views/utilities/serviceProduct/servicePackage/add")));
const EditServicePackage = Loadable(lazy(() => import("views/utilities/serviceProduct/servicePackage/edit")));
const ViewServicePackage = Loadable(lazy(() => import("views/utilities/serviceProduct/servicePackage/view")));

//gramin sathi commision

const GraminSathiCommission = Loadable(lazy(() => import("views/utilities/graminSathiCommission/index")));


// product Routes
const Product = Loadable(lazy(() => import("views/utilities/product/Product")));
const ViewProduct = Loadable(
  lazy(() => import("views/utilities/product/view-product"))
);
const AddProduct = Loadable(
  lazy(() => import("views/utilities/product/add-product"))
);
const EditProduct = Loadable(
  lazy(() => import("views/utilities/product/edit-product"))
);
const ViewVariant = Loadable(
  lazy(() => import("views/utilities/product/view-variant"))
);
const AddVariant = Loadable(
  lazy(() => import("views/utilities/product/add-variant"))
);
const EditVariant = Loadable(
  lazy(() => import("views/utilities/product/edit-variant"))
);

// inventory Routes
const Inventory = Loadable(
  lazy(() => import("views/utilities/inventory/inventory"))
);

// HRM Router
const Attenance = Loadable(
  lazy(() => import("views/utilities/HRM/attendance/attendence"))
);
const ViewAttedance = Loadable(
  lazy(() => import("views/utilities/HRM/attendance/view-attendence"))
);
const Holiday = Loadable(
  lazy(() => import("views/utilities/HRM/holiday/holiday"))
);
const AddHoliday = Loadable(
  lazy(() => import("views/utilities/HRM/holiday/add-holiday"))
);
const EditHoliday = Loadable(
  lazy(() => import("views/utilities/HRM/holiday/edit-holiday"))
);

// staff routes
const StaffManagement = Loadable(
  lazy(() => import("views/utilities/Staff Management/StaffManagement"))
);
const Permission = Loadable(
  lazy(() => import("views/utilities/Staff Management/permission/Permission"))
);
const CreateUser = Loadable(
  lazy(() => import("views/utilities/Staff Management/CreateUser"))
);

const Rolls = Loadable(
  lazy(() => import("views/utilities/Staff Management/role/Rolls"))
);
const AddRoles = Loadable(
  lazy(() => import("views/utilities/Staff Management/role/AddRoles"))
);
const ViewRole = Loadable(
  lazy(() => import("views/utilities/Staff Management/role/view-role"))
);
const EditRole = Loadable(
  lazy(() => import("views/utilities/Staff Management/role/EditRoles"))
);

const ViewStaff = Loadable(
  lazy(() => import("views/utilities/Staff Management/view-staff"))
);
const EditUser = Loadable(
  lazy(() => import("views/utilities/Staff Management/edit-user"))
);

// franchises routes
const FranchiseRequest = Loadable(
  lazy(() => import("views/utilities/Franchise/franchise-request"))
);
const FranchiseForm = Loadable(
  lazy(() => import("views/utilities/Franchise/franchise-form"))
);
const Franchise = Loadable(
  lazy(() => import("views/utilities/Franchise/franchise"))
);
const ViewFranchiseRequest = Loadable(
  lazy(() => import("views/utilities/Franchise/view-franchise-ruq"))
);
const EditFranchiseRequest = Loadable(
  lazy(() => import("views/utilities/Franchise/edit-franchise"))
);
const ViewFranchsietServiceDetail = Loadable(lazy(() => import("views/utilities/order/franchsie-service-order-detail-view")));

// password routes
const ChangePassword = Loadable(
  lazy(() => import("views/utilities/change-password/change-password"))
);

// Manage area routes
const State = Loadable(
  lazy(() => import("views/utilities/manage-area/state/state"))
);

// Manage area routes
const EditState = Loadable(
  lazy(() => import("views/utilities/manage-area/state/edit-state"))
);
const AddState = Loadable(
  lazy(() => import("views/utilities/manage-area/state/add-state"))
);

// Manage area routes
const District = Loadable(
  lazy(() => import("views/utilities/manage-area/district/district"))
);
const EditDistrict = Loadable(
  lazy(() => import("views/utilities/manage-area/district/edit-district"))
);
const AddDistrict = Loadable(
  lazy(() => import("views/utilities/manage-area/district/add-district"))
);

// Manage area routes
const PinCode = Loadable(
  lazy(() => import("views/utilities/manage-area/pin-code/pin-code"))
);
const EditPinCode = Loadable(
  lazy(() => import("views/utilities/manage-area/pin-code/edit-pin-code"))
);
const AddPinCode = Loadable(
  lazy(() => import("views/utilities/manage-area/pin-code/add-pin-code"))
);

// commission routes
const Commissiom = Loadable(
  lazy(() => import("views/utilities/commission/commission"))
);
const AddCommissiom = Loadable(
  lazy(() => import("views/utilities/commission/add-commission"))
);
const EditCommissiom = Loadable(
  lazy(() => import("views/utilities/commission/edit-commission"))
);

// application e-commerce pages
const AppECommProducts = Loadable(
  lazy(() => import("views/utilities/e-commerce/NewProduct"))
);
const AppECommProductDetails = Loadable(
  lazy(() => import("views/utilities/e-commerce/ProductDetails"))
);
const AppECommProductList = Loadable(
  lazy(() => import("views/utilities/e-commerce/ProductList"))
);
const AppECommCheckout = Loadable(
  lazy(() => import("views/utilities/e-commerce/Checkout"))
);

//farmer Request Route
const FarmerRegisterList = Loadable(
  lazy(() => import("views/utilities/farmer-register/farmer-register-list"))
);
const FarmerRegisterform = Loadable(
  lazy(() => import("views/utilities/farmer-register/service-farmer-register"))
);
const AcceptFarmerRegisterform = Loadable(
  lazy(() => import("views/utilities/farmer-register/accept-request"))
);

const FarmerRegistration = Loadable(
  lazy(() => import("views/utilities/users/add-farmer"))
);

//SubCategory routes
const SubCategory = Loadable(
  lazy(() => import("views/utilities/sub-category/subcategory"))
);
const AddSubCategory = Loadable(
  lazy(() => import("views/utilities/sub-category/add-subcategory"))
);
const EditSubCategory = Loadable(
  lazy(() => import("views/utilities/sub-category/edit-subcategory"))
);
const ViewSubCategory = Loadable(
  lazy(() => import("views/utilities/sub-category/view-subcategory"))
);

// user & frenchise payments routes
const AllUserOrder = Loadable(lazy(() => import("views/utilities/order/farmer")));
const AllFrenchiseOrder = Loadable(lazy(() => import("views/utilities/order/franchise")));
const ViewFranchiseOrder = Loadable(lazy(() => import("views/utilities/order/view-franchise-order")));
const ViewUserOrder = Loadable(lazy(() => import("views/utilities/order/service-order-detail-view")));
const FranchiseCodAccept = Loadable(lazy(() => import("views/utilities/order/cod-franchise-accept")));

const FrenchiseUserOrder = Loadable(lazy(() => import("views/utilities/order/frenchiseUserOrder")));

const OrderSuccess = Loadable(lazy(() => import("views/utilities/order/order-success")));


const Checkout = Loadable(lazy(() => import("views/utilities/checkout/checkout")));

const Transaction = Loadable(
  lazy(() => import("views/utilities/Transaction History/transaction"))
);
const FranchiseLedger = Loadable(
  lazy(() => import("views/utilities/Transaction History/frenchiseTransaction"))
);

//Ledger Routes

const GetLedger = Loadable(
  lazy(() => import("views/utilities/ledger/leadger-history"))
);



//services routes
const Services = Loadable(lazy(() => import("views/utilities/services/serviceRequest")));
const ProductSettlement = Loadable(lazy(() => import("views/utilities/settlement/product")));
const ProductSettlementHistory = Loadable(lazy(() => import("views/utilities/settlement/product/settlement-history")));
const ServiceSettlement = Loadable(lazy(() => import("views/utilities/settlement/service")));
const ServiceSettlementHistory = Loadable(lazy(() => import("views/utilities/settlement/service/settlement-history")));
// const ServiceSettlement = Loadable(lazy(() => import("views/utilities/settlement/Service")));
const ServiceHisory = Loadable(
  lazy(() => import("views/utilities/services/serviceHitory"))
);
const AcceptService = Loadable(
  lazy(() => import("views/utilities/services/serviceAccept"))
);

const ProductLead = Loadable(
  lazy(() => import("views/utilities/crm/productLead/productLead"))
);

const ServiceLead = Loadable(
  lazy(() => import("views/utilities/crm/productLead/serviceLead"))
);

const StorySet = Loadable(lazy(() => import("views/utilities/storySet/index")));
// sample page routing
// ==============================|| MAIN ROUTING ||============================== //
const Page404 = Loadable(
  lazy(() => import("views/utilities/404"))
);

//Advaisory Routes

//Weather
const AdvisioryWheather = Loadable(
  lazy(() => import("views/utilities/advisiory/weather/addWhether.advisiory"))
);

const AdvisioryWheatherNotificationHistory = Loadable(
  lazy(() => import("views/utilities/advisiory/weather/weatherHistory.advisory"))
);

//Pesticide
const AdvisioryPesticide = Loadable(
  lazy(() => import("views/utilities/advisiory/pesticide/addPesticide.advisiory"))
);

const AdvisioryPesticideHistory = Loadable(
  lazy(() => import("views/utilities/advisiory/pesticide/pesticideHistory.advisory"))
);


//Information 
const AdvisioryInformation = Loadable(
  lazy(() => import("views/utilities/advisiory/importantInformation/addInformation.advisiory"))
);

const AdvisioryInformationHistory = Loadable(
  lazy(() => import("views/utilities/advisiory/importantInformation/informationHistory.advisory"))
);


//Disease
const AdvisioryDisease = Loadable(
  lazy(() => import("views/utilities/advisiory/disease/addDisease.advisiory"))
);

const AdvisioryDiseaseHistory = Loadable(
  lazy(() => import("views/utilities/advisiory/disease/diseaseHistory.advisory"))
);


//Fertiliser
const AdvisioryFertiliser = Loadable(
  lazy(() => import("views/utilities/advisiory/fertiliser/addFertiliser.advisiory"))
);

const AdvisioryFertiliserHistory = Loadable(
  lazy(() => import("views/utilities/advisiory/fertiliser/fertiliserHistory.advisory"))
);


//Personalised
const AdvisioryPersonalised = Loadable(
  lazy(() => import("views/utilities/advisiory/personalisedCrop/addPersonalised.advisiory"))
);

const AdvisioryPersonalisedHistory = Loadable(
  lazy(() => import("views/utilities/advisiory/personalisedCrop/personalisedHistory.advisory"))
);

const LoanDetail = Loadable(
  lazy(() => import("views/utilities/loan/loanDetail"))
);

const AddLoan = Loadable(
  lazy(() => import("views/utilities/loan/addLoan"))
);


const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      // path: "/payment-success",
      // element :(
      //   <IsLogin isLoggedIn={userDetails}>
      //     <StorySet />
      //   </IsLogin> 
      // )
      // element: <StorySet />,
    },
    {
      path: "*",
      element: <Page404 />
    },
    {
      path: "/dashboard",
      element: (
        <Protected isLoggedIn={userDetails}>
          <Dashboard />
        </Protected>
      )
      // element: <Dashboard />,
    },
    {
      path: "/send-weather-notifications",
      element: (
        <Protected isLoggedIn={userDetails}>
          <AdvisioryWheather />
        </Protected>
      )
    },
    {
      path: "/weather-notifications-advisory",
      element: (
        <Protected isLoggedIn={userDetails}>
          <AdvisioryWheatherNotificationHistory />
        </Protected>
      )
    },
    {
      path: "/send-pesticide-notifications",
      element: (
        <Protected isLoggedIn={userDetails}>
          <AdvisioryPesticide />
        </Protected>
      )
    },
    {
      path: "/notifications-pesticide",
      element: (
        <Protected isLoggedIn={userDetails}>
          <AdvisioryPesticideHistory />
        </Protected>
      )
    },
    {
      path: "/send-information-notifications",
      element: (
        <Protected isLoggedIn={userDetails}>
          <AdvisioryInformation />
        </Protected>
      )
    },
    {
      path: "/notifications-information",
      element: (
        <Protected isLoggedIn={userDetails}>
          <AdvisioryInformationHistory />
        </Protected>
      )
    },
    {
      path: "/send-disease-notifications",
      element: (
        <Protected isLoggedIn={userDetails}>
          <AdvisioryDisease />
        </Protected>
      )
    },
    {
      path: "/notifications-disease",
      element: (
        <Protected isLoggedIn={userDetails}>
          <AdvisioryDiseaseHistory />
        </Protected>
      )
    },
    {
      path: "/send-fertiliser-notifications",
      element: (
        <Protected isLoggedIn={userDetails}>
          <AdvisioryFertiliser />
        </Protected>
      )
    },
    {
      path: "/notifications-fertiliser",
      element: (
        <Protected isLoggedIn={userDetails}>
          <AdvisioryFertiliserHistory />
        </Protected>
      )
    },
    {
      path: "/send-personalised-notifications",
      element: (
        <Protected isLoggedIn={userDetails}>
          <AdvisioryPersonalised />
        </Protected>
      )
    },
    {
      path: "/notifications-personalised",
      element: (
        <Protected isLoggedIn={userDetails}>
          <AdvisioryPersonalisedHistory />
        </Protected>
      )
    },
    {
      path: "/franchiseDashboard",
      element: <FranchiseDashboard />
    },
    {
      path: "/transaction-history",
      element: (
        <IsLogin isLoggedIn={userDetails}>
          <Transaction />
        </IsLogin>
      )
    },
    {
      path: "/product-settlement",
      element: (
        <Protected isLoggedIn={userDetails}>
          <ProductSettlement />
        </Protected>
      )
    },
    {
      path: "/service-settlement/",
      element: (
        <Protected isLoggedIn={userDetails}>
          <ServiceSettlement />
        </Protected>
      )

    },
    {
      path: "/service-settlement-history/:id",
      element: (
        <IsLogin isLoggedIn={userDetails}>
          <ServiceSettlementHistory />
        </IsLogin>
      )
    },
    {
      path: "/product-settlement-history/:id",
      element: (
        <IsLogin isLoggedIn={userDetails}>
          <ProductSettlementHistory />
        </IsLogin>
      )
    },
    {
      path: "/frenchise-transaction-history",
      element: (
        <IsLogin isLoggedIn={userDetails}>
          <FranchiseLedger />
        </IsLogin>
      )
    },
    {
      path: "/frenchise-card",
      element: (
        <IsLogin isLoggedIn={userDetails}>
          <FranchiseCard />
        </IsLogin>
      )
    },
    {
      path: "/product-lead",
      element: (
        <IsLogin isLoggedIn={userDetails}>
          <ProductLead />
        </IsLogin>
      )
    },
    {
      path: "/service-lead",
      element: (
        <IsLogin isLoggedIn={userDetails}>
          <ServiceLead />
        </IsLogin>
      )
    },
    {
      path: "/ledger-history",
      element: (
        <Protected isLoggedIn={userDetails}>
          <GetLedger />
        </Protected>
      )
    },
    {
      path: "/orderplaced/:id",
      element: (
        <IsLogin isLoggedIn={userDetails}>
          <Checkout />
        </IsLogin>
      )

    },
    // SERVICE ROUTES
    {
      children: [
        {
          path: "add-service",
          element: (
            <IsLogin isLoggedIn={userDetails}>
              <AddService />
            </IsLogin>
          )
        }, {
          path: "edit-service/:id",
          element: (
            <IsLogin isLoggedIn={userDetails}>
              <EditService />
            </IsLogin>
          )
        }, {
          path: "view-service/:id",
          element: (
            <Protected isLoggedIn={userDetails}>
              <ViewService />
            </Protected>
          )
        },
        {
          path: "get-services",
          element: (
            <Protected isLoggedIn={userDetails}>
              <ServiceAll />
            </Protected>
          )
        },
        // Service Categories
        {
          path: "add-service-category",
          element: (
            <Protected isLoggedIn={userDetails}>
              <AddCategoryService />
            </Protected>
          )
        }, {
          path: "edit-service-category/:id",
          element: <EditCategoryService />,
        }, {
          path: "view-service-category/:id",
          element: <ViewCategoryService />,
        }
        , {
          path: "get-service-category",
          element: (
            <Protected isLoggedIn={userDetails}>
              <ServiceCategoryAll />
            </Protected>
          )
        },
        // Service Type
        {
          path: "add-service-type",
          element: (
            <Protected isLoggedIn={userDetails}>
              <AddServiceType />
            </Protected>
          )

        }, {
          path: "edit-service-type/:id",
          element: <EditServiceType />,
        }, {
          path: "view-service-type/:id",
          element: <ViewServiceType />,
        }
        , {
          path: "get-service-type",
          element: (
            <Protected isLoggedIn={userDetails}>
              <ServiceTypeAll />
            </Protected>
          )
        },

        // Service Packages
        {
          path: "add-service-package",
          element: (
            <Protected isLoggedIn={userDetails}>
              <AddServicePackage />
            </Protected>
          )
        }, {
          path: "edit-service-package/:id",
          element: <EditServicePackage />,
        }, {
          path: "view-service-package/:id",
          element: <ViewServicePackage />,
        }
        , {
          path: "get-service-package",
          element: (
            <Protected isLoggedIn={userDetails}>
              <ServicePackageAll />
            </Protected>
          )
        }
,
        {
          path: "gramin-sathi-commission",
          element: (
            <Protected isLoggedIn={userDetails}>
              <GraminSathiCommission />
            </Protected>
          )

        },
      ]
    },
    {
      children: [
        {
          path: "service-request",
          element: <Services />,
        },
        {
          path: "service-history",
          element: <ServiceHisory />,
        },
        {
          path: "accept-service",
          element: <AcceptService />,
        },
      ],
    },
    {
      children: [
        {
          path: "farmer-service-request",
          element: (
            <Protected isLoggedIn={userDetails}>
              <AllUserOrder />
            </Protected>
          )
        },
        {
          path: "franchise-order",
          element: <AllFrenchiseOrder />,
        },
        {
          path: "view-user-order/:id",
          element: <ViewUserOrder />,
        },
        {
          path: "view-franchise-user-service-order/:id",
          element: <ViewFranchsietServiceDetail />,
        },
        {
          path: "franchise-cod-accept/:id",
          element: <FranchiseCodAccept />,
        },
        {
          path: "payment-success/:id",
          element: <OrderSuccess />
        },
        {
          path: "frenchise-order-history",
          element: <FrenchiseUserOrder />,
        },
        {
          path: "view-franchise-order/:id",
          element: <ViewFranchiseOrder />,
        },
      ],
    },
    {
      path: "/our/products",
      element: <AppECommProducts />,
    },
    {
      path: "/our/product-details/:id",
      element: <AppECommProductDetails />,
    },
    {
      path: "/our/product-list",
      element: <AppECommProductList />,
    },
    {
      path: "/our/checkout",
      element: <AppECommCheckout />,
    },

    {
      path: "/inventory",
      element: <Inventory />,
    },
    {
      children: [
        {
          path: "commission",
          element: <Commissiom />,
        },
        {
          path: "add-commission",
          element: <AddCommissiom />,
        },
        {
          path: "edit-commission/:id",
          element: <EditCommissiom />,
        },
      ],
    },
    {
      children: [
        {
          path: "banner",
          element: <Banner />,
        },
        {
          path: "add-banner",
          element: <AddBanner />,
        },
        {
          path: "edit-banner/:id",
          element: <EditBanner />,
        },
      ],
    },
    {
      children: [
        {
          path: "staff-users",
          element: (
            <Protected isLoggedIn={userDetails}>
              <StaffManagement />
            </Protected>
          )
          // element: <StaffManagement />,
        },
        {
          path: "role",
          element: (
            <Protected isLoggedIn={userDetails}>
              <Rolls />
            </Protected>
          )
        },
        {
          path: "permission",
          element: (
            <Protected isLoggedIn={userDetails}>
              <Permission />
            </Protected>
          )
        },
        {
          path: "create-user",
          element: <CreateUser />,
        },
        {
          path: "edit-user/:id",
          element: <EditUser />,
        },
        {
          path: "add-roles",
          element: <AddRoles />,
        },
        {
          path: "view-roles/:id",
          element: <ViewRole />,
        },
        {
          path: "edit-roles/:id",
          element: <EditRole />,
        },
        {
          path: "view-staff/:id",
          element: <ViewStaff />,
        },
      ],
    },
    {
      children: [
        {
          path: "purchase-history",
          element: <PurchaseHistory />,
        },
        {
          path: "view-purchase-history/:id",
          element: <ViewPurchaseHistory />,
        },
      ],
    },
    {
      children: [
        {
          path: "franchise",
          element: (
            <Protected isLoggedIn={userDetails}>
              <Franchise />
            </Protected>
          )
        },
        {
          path: "franchise-request-form",
          element: <FranchiseForm />,
        },
        {
          path: "FarmerRegistration-form",
          element: <FarmerRegistration />
        },
        {
          path: "franchise-request",
          element: (
            <Protected isLoggedIn={userDetails}>
              <FranchiseRequest />
            </Protected>
          )
        },
        {
          path: "view-franchise-request/:id",
          element: <ViewFranchiseRequest />,
        },
        {
          path: "edit-franchise-request/:id",
          element: <EditFranchiseRequest />,
        },
      ],
    },
    {
      children: [
        {
          path: "state",
          element: <State />,
        },
        {
          path: "edit-state/:id",
          element: <EditState />,
        },
        {
          path: "add-state",
          element: <AddState />,
        },
        // district
        {
          path: "district",
          element: <District />,
        },
        {
          path: "edit-district/:id",
          element: <EditDistrict />,
        },
        {
          path: "add-district",
          element: <AddDistrict />,
        },
        // zip code
        {
          path: "pin-code",
          element: <PinCode />,
        },
        {
          path: "edit-pin-code/:id",
          element: <EditPinCode />,
        },
        {
          path: "add-pin-code",
          element: <AddPinCode />,
        },
      ],
    },
    {
      children: [
        {
          path: "users",
          element: <Users />,
        },
        {
          path: "frenchise-users",
          element: <FranchiseUsers />,
        },
        {
          path: "view-user/:id",
          element: <ViewUsers />,
        },
      ],
    },
    {
      children: [
        {
          path: "change-passwrd",
          element: <ChangePassword />,
        },
      ],
    },
    {
      children: [
        {
          path: "category",
          element: <Category />,
        },
        {
          path: "add-category",
          element: <AddCategory />,
        },
        {
          path: "edit-category/:id",
          element: <EditCategory />,
        },
        {
          path: "view-category/:id",
          element: <ViewCategory />,
        },
      ],
    },
    {
      children: [
        {
          path: "sub-category",
          element: <SubCategory />,
        },
        {
          path: "add-subcategory",
          element: <AddSubCategory />,
        },
        {
          path: "edit-subcategory/:id",
          element: <EditSubCategory />,
        },
        {
          path: "view-subcategory/:id",
          element: <ViewSubCategory />,
        },
      ],
    },
    {
      children: [
        {
          path: "product",
          element: <Product />,
        },
        {
          path: "add-product",
          element: <AddProduct />,
        },
        {
          path: "add-variant",
          element: <AddVariant />,
        },
        {
          path: "edit-product/:id",
          element: <EditProduct />,
        },
        {
          path: "edit-variant/:id/:pId",
          element: <EditVariant />,
        },
        {
          path: "view-product/:id",
          element: <ViewProduct />,
        },
        {
          path: "view-variant/:id",
          element: <ViewVariant />,
        },
      ],
    },
    {
      children: [
        {
          path: "attedance",
          element: <Attenance />,
        },
        {
          path: "view-attendence",
          element: <ViewAttedance />,
        },
      ],
    },
    {
      children: [
        {
          path: "holidays",
          element: <Holiday />,
        },
        {
          path: "add-holiday",
          element: <AddHoliday />,
        },
        {
          path: "edit-holiday/:id",
          element: <EditHoliday />,
        },
      ],
    },
    {
      children: [
        {
          path: "farmer-register-List",
          element: <FarmerRegisterList />,
        },
        {
          path: "farmer-register-form",
          element: <FarmerRegisterform />,
        },
        {
          path: "/accept-farmer/request/:id",
          element: <AcceptFarmerRegisterform />,
        },
      ],
    },
    {
      children: [
        {
          path: "loan-detail",
          element: <LoanDetail />,
        },
        {
          path: "add-loan",
          element: <AddLoan />,
        },

      ],
    },
  ],
};

export default MainRoutes;
