import { createSlice } from "@reduxjs/toolkit";
import {
  getTokenLocal,
  getUserLocal,
  setTokenLocal,
  setUserLocal,
  getPermissionLocal,
  setPermissionLocal,
  setFranchiseLocal,
  getFranchiseLocal
} from "../../utils/localStorage.util";

const initialState = {
  v_user_info: getUserLocal(),
  x_auth_token: getTokenLocal(),
  permission: getPermissionLocal(),
  User: [],
  Lead : [],
  ServiceLead : [],
  FranchiseData : [],

};

//internally using immer lib (can create mutable state)
export const userSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    updateUser: (state, action) => {
      setUserLocal(action.payload);
      state.v_user_info = action.payload;
    },
    updateFranchise: (state, action) => {
      setFranchiseLocal(action.payload);
      state.FranchiseData = action.payload;
    },
    updateToken: (state, action) => {
      setTokenLocal(action.payload);
      state.x_auth_token = action.payload;
    },
    updatePermission: (state, action) => {
      setPermissionLocal(action.payload);
      state.permission = action.payload;
    },
    updateAllUser: (state, action) => {
      state.User = action.payload;
    },
    updateAllLead: (state, action) => {
      state.Lead = action.payload;
    },
    updateAllServiceLead: (state, action) => {
      state.ServiceLead = action.payload;
    },
  },
});

// this is for dispatch
export const { updateUser, updateToken, updateAllUser, updatePermission,updateAllLead,updateAllServiceLead,updateFranchise} = userSlice.actions;

// this is for configureStore
export default userSlice.reducer;
