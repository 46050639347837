import { getTokenLocal } from "../utils/localStorage.util";
import ApiRoutes from "../conffigs/endpoints.config";
import HttpClient from "./index.api";
const baseURL = process.env.REACT_APP_API_URL;

class Frencise extends HttpClient {
  constructor() {
    super(baseURL);
    this._initializeRequestInterceptor();
    this._initializeResponseInterceptor();
  }

  _initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use((config) => {
      config.headers["Authorization"] = `Bearer ${getTokenLocal()}`;
      return config;
    });
  };

  _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (response) => {
        return Promise.resolve(response);
      }
    );
  };

  AllFrenciseConfig = ApiRoutes.Frencise.All;
  AllFrenciseAcceptConfig = ApiRoutes.Frencise.FrenciseAccept;
  FrenciseByIdConfig = ApiRoutes.Frencise.FrenciseById;
  AddFrenciseConfig = ApiRoutes.Frencise.AddFrencise;
  EditFrenciseConfig = ApiRoutes.Frencise.EditFrencise;
  DeleteFrenciseConfig = ApiRoutes.Frencise.DeleteFrencise;
  FrenciseCheckConfig = ApiRoutes.Frencise.FrenciseCheck;
  FrenchiseUserConfig = ApiRoutes.Frencise.FrenchiseUser;
  FranchiseTransactionConfig = ApiRoutes.Frencise.FranchiseTransaction;
  FrenchiseOrderConfig = ApiRoutes.Frencise.FrenchiseOrder;



  //for loan 

  getLoanDetailConfig = ApiRoutes.Loan.getLoanDetail;
  addLoanConfig = ApiRoutes.Loan.addLoan;
  getLoanTypeConfig = ApiRoutes.Loan.getLoanType;

  getAllFrencise = async () => {
    return this.instance({
      method: this.AllFrenciseConfig.Method,
      url: this.AllFrenciseConfig.Endpoint,
      headers: {},
      data: null,
    });
  };
  getAllFrenciseAccept = async () => {
    return this.instance({
      method: this.AllFrenciseAcceptConfig.Method,
      url: this.AllFrenciseAcceptConfig.Endpoint,
      headers: {},
      data: null,
    });
  };
  getFrenciseById = async (data) => {
    return this.instance({
      method: this.FrenciseByIdConfig.Method,
      url: this.FrenciseByIdConfig.Endpoint,
      headers: {},
      data: data,
    });
  };
  frenciseCheck = async (data) => {
    return this.instance({
      method: this.FrenciseCheckConfig.Method,
      url: this.FrenciseCheckConfig.Endpoint,
      headers: {},
      data: data,
    });
  };
  addFrencise = async (data) => {
    return this.instance({
      method: this.AddFrenciseConfig.Method,
      url: this.AddFrenciseConfig.Endpoint,
      headers: {},
      data: data,
    });
  };
  editFrencise = async (data) => {
    return this.instance({
      method: this.EditFrenciseConfig.Method,
      url: this.EditFrenciseConfig.Endpoint,
      headers: {},
      data: data,
    });
  };
  deleteFrencise = async (data) => {
    return this.instance({
      method: this.DeleteFrenciseConfig.Method,
      url: this.DeleteFrenciseConfig.Endpoint,
      headers: {},
      data: data,
    });
  };
  FrenchiseUser = async (data) => {
    return this.instance({
      method: this.FrenchiseUserConfig.Method,
      url: this.FrenchiseUserConfig.Endpoint,
      headers: {},
      data: data,
    });
  };
  FranchiseTransaction = async (data) => {
    return this.instance({
      method: this.FranchiseTransactionConfig.Method,
      url: this.FranchiseTransactionConfig.Endpoint,
      headers: {},
      data: data,
    });
  };
  FrenchiseOrder = async (data) => {
    return this.instance({
      method: this.FrenchiseOrderConfig.Method,
      url: this.FrenchiseOrderConfig.Endpoint,
      headers: {},
      data: data,
    });
  };


  //for loan 

  getLoanData = async (data) => {
    return this.instance({
      method: this.getLoanDetailConfig.Method,
      url: this.getLoanDetailConfig.Endpoint,
      headers: {},
      data: data,
    });
  }
  addLoan = async (data) => {
    return this.instance({
      method: this.addLoanConfig.Method,
      url: this.addLoanConfig.Endpoint,
      headers: {},
      data: data,
    });
  }
  getLoanType = async (data) => {
    return this.instance({
      method: this.getLoanTypeConfig.Method,
      url: this.getLoanTypeConfig.Endpoint,
      headers: {},
      data: data,
    });
  }
}

export default Frencise;
