import { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import { getUserLocal } from "utils/localStorage.util";

const SocketContext = createContext();

export const useSocket = () => {
  return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const userId = getUserLocal();

  useEffect(() => {
    const newSocket = io(process.env.REACT_APP_SOCKET_API); // Replace with your server URL
    setSocket(newSocket);
    if(userId?.staffId){
        newSocket.emit("addUser", userId?.staffId, "khetiAdmin");
     }
    return () => newSocket.close();
  }, []);

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};
