import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  WheatherNotification: [],
  PersonaliseNotification:[],
  FertigationNotification:[],
  PesticideNotification:[]
};
//internally using immer lib (can create mutable state)
export const advisiorySlice = createSlice({
  name: "advisoryData",
  initialState,
  reducers: {
    updateNotification: (state, action) => {
      state.WheatherNotification = action.payload;
    },
    updatePersonaliseNotification: (state, action) => {
      state.PersonaliseNotification = action.payload;
    },
    updateFertigationNotification: (state, action) => {
      state.FertigationNotification = action.payload;
    },
    updatePesticideNotification: (state, action) => {
      state.PesticideNotification = action.payload;
    }
  },
});
// this is for dispatch
export const { updateNotification ,updatePersonaliseNotification,updateFertigationNotification,updatePesticideNotification} = advisiorySlice.actions;
// this is for configureStore
export default advisiorySlice.reducer;
